.projectHolders {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
  button {
    min-height: 51px;
    &:first-child {
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
  }
}
