.floatingBubble {
    position: fixed;
    bottom: 20px;
    left: calc(50% - 250px);
    height: 21px;
    width: 500px;
    text-align: center;
    font-size: 13px;
    z-index: 100;
    color: #fff;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 250ms;
    a {
        color: #172b4d;
        font-weight: bold;
    }
}