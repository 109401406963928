.floating-button-v2 {
    background-color: #2dce89;
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 50px;
    width: 200px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    z-index: 100;
    color: #fff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    // line-height: 96px;
    cursor: pointer;
    transition: background-color 250ms;
    &:hover {
        background-color: #167e51;
        color: #fff;
        box-shadow: 0px 0px 18px #747474;
    }
}

.floating-button {
    background-color: #2dce89;
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 100px;
    width: 100px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    z-index: 100;
    color: #fff;
    border-radius: 100px;
    line-height: 96px;
    cursor: pointer;
    transition: background-color 250ms;
    &:hover {
        background-color: #167e51;
        color: #fff;
        box-shadow: 0px 0px 18px #747474;
    }
}